import React from 'react'
import { withRouter } from 'next/router'
import Link from 'next/link'
import { HeaderNavLink } from '../v2/Header'

const ActiveLink = ({ children, router, href, as, classes, ...props }) => {
  const active = router?.pathname === href
  const className = active ? 'active' : ''
  return (
    <Link href={href || ''} as={as}>
      <HeaderNavLink href={href || ''} className={`nav-link ${className} ${classes}`} {...props}>
        {' '}
        {children}{' '}
      </HeaderNavLink>
    </Link>
  )
}

export default withRouter(ActiveLink)
