import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, layout, typography, color, variant, position } from 'styled-system'
import propTypes from '@styled-system/prop-types'

const LIGHT = 'light'
const DARK = 'dark'

const SIZES = {
  h1: '48px',
  h2: '28px',
  h3: '18px',
  h4: 16,
  h5: 14,
  h6: 12
}

const Title = styled.p(
  ({ shadow, props }) => `
          font-size: ${SIZES[props?.type]};
          margin: 0px;
          ${shadow ? 'text-shadow: 0px 1px 1px #00000033;' : ''}
        `,
  variant({
    variants: {
      [LIGHT]: {
        color: '#fff'
      },
      [DARK]: {
        color: '#212121'
      }
    }
  }),

  space,
  layout,
  typography,
  color,
  position
)

Title.propTypes = {
  variant: PropTypes.oneOf([LIGHT, DARK]),
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.color,
  ...propTypes.position
}

Title.defaultProps = {
  variant: 'dark',
  fontFamily: 'Sora',
  shadow: false
}

export { Title }
