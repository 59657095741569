import React from 'react'
import { checkIsMobile } from 'utils/helpers'
import { Row } from '../Grid'
import { NextImage } from '../NextImage'

const HeaderCorporate = () => (
  <Row
    background='#FFFFFF'
    boxShadow='0px 1px 3px 0px #0000001F'
    width='100%'
    height={[68, 107]}
    justifyContent='center'
    position='fixed'
    top={0}
    zIndex={2}
  >
    <NextImage width={checkIsMobile(662) ? 88 : 130} src='/static/images/logo-vittude.svg' />
  </Row>
)

export { HeaderCorporate }
