import React, { useState, useEffect, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import Router from 'next/router'
import { destroyCookie } from 'nookies/dist'
import { useDispatch } from 'react-redux'
import FavoriteContext from 'context/favoriteContext'

import { NextImage } from 'components/v2/NextImage'
import { Column, Row } from 'components/v2/Grid'
import { DropdownMenuItems } from 'components/v2/Header'
import { Text } from 'components/v2/Text'
import { NavbarMobile } from 'components/v2/NavbarMobile'
import { Twirl as Hamburger } from 'hamburger-react'

import { logout, getProfile, getToken, getRefreshToken } from 'services/auth'
import { getCartBalance } from 'services/shoppingCart'
import { clearSignUp } from 'redux/modules/signUp'
import { deleteDataContractId } from 'utils/helpers'
import { useFeatureFlags } from 'providers/FeatureFlagsProvider'
import { useOutsideClick } from 'hooks'
import { useFeatureFlags as featureFlagHook } from 'hooks/useFeatureFlags'
import { Beacon } from './Beacon'
import { useBeaconIncidents } from 'hooks'
import TimezoneContext from 'context/timezoneContext'

const HomeHeaderMobile = ({ openModal }) => {
  const { frontend_beacon_platform_feature } = featureFlagHook('frontend_beacon_platform_feature')

  const { getFeatures } = useFeatureFlags()

  const [user, setUser] = useState(null)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false)
  const [isProfessional, setIsProfessional] = useState(false)
  const [balance, setBalance] = useState({})
  const [beaconMenu, setBeaconMenu] = useState(false)
  const { likeProfessionals } = useContext(FavoriteContext)

  const dispatch = useDispatch()
  const profile = getProfile()
  const refreshToken = getRefreshToken()
  const token = getToken()

  const userIsProfessional = profile && profile?.role === 'professional'
  const userIsLogged = profile && refreshToken && token
  const { timezone } = useContext(TimezoneContext)
  const { data } = useBeaconIncidents(userIsLogged, timezone, userIsProfessional)

  const handleBalance = async () => {
    try {
      const { data } = await getCartBalance(profile.billing_account_id)
      setBalance(data?.content)
    } catch (error) {
      if (error?.response?.status === 401) {
        setUser(null)
        setIsUserLoggedIn(false)
        setIsProfessional(false)
      }
      console.log(error)
    }
  }

  useEffect(async () => {
    if (userIsLogged) {
      setUser(profile)
      setIsUserLoggedIn(true)
    }

    if (userIsLogged && userIsProfessional) {
      setIsProfessional(true)
    }

    if (userIsLogged && profile?.billing_account_id) {
      handleBalance()
    }
  }, [])

  const handleLogOut = async () => {
    try {
      getFeatures()
      await logout()

      destroyCookie(null, 'contractId')
      deleteDataContractId()
      setIsUserLoggedIn(false)
      setIsProfessional(false)

      toast.success('Você saiu da conta.')
    } catch (error) {
      console.log(error)
    } finally {
      Router.push({
        pathname: '/'
      })
    }
  }

  const handleCloseDropdownItems = () => {
    setOpenDropdownMenu(!openDropdownMenu)
  }

  const handleBeaconClick = () => {
    if (beaconMenu === false && data?.beaconsIncidents) {
      const incidentsList = data?.beaconsIncidents?.map(incident => ({
        title: incident?.title,
        state: incident?.type
      }))
      sendBeaconIncidentsListEvent(incidentsList)
    }
    setBeaconMenu(!beaconMenu)
  }

  const ref = useOutsideClick(() => setBeaconMenu(false))

  return (
    <Column
      id='header'
      position={openDropdownMenu ? 'fixed' : 'relative'}
      height={openDropdownMenu ? '110%' : 'auto'}
      zIndex={openDropdownMenu ? '999' : '1'}
      width='100%'
    >
      <NavbarBackground id='header-nav-bar' isProfessional={isProfessional}>
        <HeaderContent>
          <Hamburger
            toggle={handleCloseDropdownItems}
            toggled={openDropdownMenu}
            color={isProfessional ? '#fff' : '#313a4d'}
            size={22}
          />
          {isProfessional ? (
            frontend_beacon_platform_feature ? (
              <>
                <Column>
                  <NextImage
                    src='/static/images/logo-vittude-white.svg'
                    alt='Vittude'
                    title='Logo da Vittude White'
                    height={26}
                    width={87}
                    onClick={() => {
                      Router.push('/')
                    }}
                    priority
                  />
                  <Text textAlign='end' letterSpacing='2px' fontSize='10px' color='white'>
                    PSICÓLOGO
                  </Text>
                </Column>
                <Row ref={ref}>
                  <ButtonStatus onClick={handleBeaconClick}>
                    <NextImage
                      src={data?.icon || '/static/images/logo-vittude-white.svg'}
                      width={20}
                      height={20}
                    />
                  </ButtonStatus>
                  <Beacon
                    isActive={beaconMenu}
                    icon={data?.icon || '/static/images/logo-vittude-white.svg'}
                    havingIncidents={data?.isHavingIncidents}
                    incidents={data?.beaconsIncidents}
                  />
                </Row>
              </>
            ) : (
              <Column>
                <NextImage
                  src='/static/images/logo-vittude-white.svg'
                  alt='Vittude'
                  title='Logo da Vittude White'
                  height={26}
                  width={87}
                  onClick={() => {
                    Router.push('/')
                  }}
                  priority
                />
                <Text textAlign='end' letterSpacing='2px' fontSize='10px' color='white'>
                  PSICÓLOGO
                </Text>
              </Column>
            )
          ) : (
            <>
              <NextImage
                src='/static/images/logo-vittude.svg'
                alt='vittude-logo'
                title='Logo da Vittude'
                width={125}
                height={42}
                onClick={() => {
                  localStorage.setItem('resetSteps', 'true')
                  Router.push('/')
                }}
                priority
              />
              <Row>
                <VisitorsMenu>
                  <DropdownRow>
                    <Row>
                      {openDropdownMenu && !isProfessional && (
                        <DropdownMenuItems
                          openDropdown={() => handleCloseDropdownItems()}
                          isUserLoggedIn={isUserLoggedIn}
                          isProfessional={isProfessional}
                          handleLogOut={handleLogOut}
                          balance={balance}
                          user={user}
                          openDropdownMenu={openDropdownMenu}
                          setOpenDropdownMenu={setOpenDropdownMenu}
                          likeProfessionals={likeProfessionals}
                          openModal={openModal}
                        />
                      )}
                    </Row>
                  </DropdownRow>
                </VisitorsMenu>
              </Row>
            </>
          )}
        </HeaderContent>
      </NavbarBackground>
      {isProfessional && openDropdownMenu && (
        <NavbarMobile
          logoutUser={handleLogOut}
          clearSignUp={() => dispatch(clearSignUp())}
          user={user}
          closeToggle={() => handleCloseDropdownItems()}
        />
      )}
    </Column>
  )
}

const NavbarBackground = styled(Row)(
  ({ isProfessional, theme: { colors } }) => `
  padding: 0 30px 0 30px;
  min-height: 60px;
  width: 100%;
  background: ${isProfessional ? colors.secondary : colors.white};

  @media (max-width: 320px) {
    padding: 0 10px;
  }
`
)

const HeaderContent = styled(Row)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 2;
  flex-direction: row-reverse;
`

const VisitorsMenu = styled(Row)`
  align-items: center;
`

const DropdownRow = styled(Row)`
  cursor: pointer;
`

const ButtonStatus = styled(Row)`
  ${({ theme: { colors }, active }) => `
    background: #17233e;
    padding: 9px 12px;
    border-radius: 10px;
    align-self: center;
    cursor: pointer;
    transition: 0.3s all;
    font-weigth: 500;
    max-height: 40px;

    &:hover {
      background: #081124;
    }

    @media (max-width: 640px) {
      min-height: 48px;
      align-self: right
    }
`}
`

export { HomeHeaderMobile }
