import React, { useState, useRef } from 'react'
import { useRouter } from 'next/router'
import AvatarEditor from 'react-avatar-editor'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { Text } from 'components/v2/Text'
import { Close } from 'components/v2/Icons'
import { Modal } from 'components/v2/Modal'
import { Button } from 'components/v2/Button'
import { Column, Row } from 'components/v2/Grid'
import { RangeSlider } from 'components/v2/RangeSlider'

import { updatePhoto } from 'services/userService'

const ChangeAvatarModal = ({ isModalOpen, setIsModalOpen }) => {
  const editor = useRef()
  const router = useRouter()

  const [image, setImage] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [filename, setFilename] = useState('')
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)

  const onSelectFile = e => {
    e.preventDefault()

    const files = e.target.files

    if (!files || files.length === 0) return

    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
      setCroppedImage(reader.result)
      setFilename(files[0].name)
    }

    reader?.readAsDataURL(files[0])
  }

  const resetImage = () => {
    setImage(null)
    setCroppedImage(null)
    setFilename('')
    setIsModalOpen(false)
  }

  const onImageChange = () => {
    if (!editor || !editor.current) return

    const canvas = editor.current.getImageScaledToCanvas()
    const image = canvas.toDataURL()
    setCroppedImage(image)
  }

  const mutation = useMutation({
    mutationFn: async () => {
      toast.info('Aguarde enquanto salvamos a imagem')
      await updatePhoto(croppedImage, filename)
    },
    onSuccess: () => {
      toast.success('Foto atualizada com sucesso')
      resetImage()
      router.reload()
    },
    onError: error => {
      toast.error('Erro ao atualizar foto')
      console.log(error)
      resetImage()
    }
  })

  return (
    <Modal
      isOpened={isModalOpen}
      setIsOpened={open => setIsModalOpen(open)}
      shouldCloseOnClickOutside={true}
      shouldCloseOnEscape={true}
      withBackground={true}
    >
      {({ innerModalRef }) => (
        <Column
          ref={innerModalRef}
          height='auto'
          borderRadius='6px'
          padding='24px'
          backgroundColor='white'
          boxShadow='0px 4px 8px rgba(0, 0, 0, 0.15)'
        >
          <Column width='100%' height='100%'>
            <Row
              onClick={() => {
                resetImage()
                setIsModalOpen(false)
              }}
              justifyContent='flex-end'
              mb='8px'
            >
              <Close size='14px' />
            </Row>
            <Column width='100%' height='100%' justifyContent='space-between'>
              <Text fontSize='20px' fontWeight='600' lineHeight='24px' textAlign='left' mb='16px'>
                Posicione/Redimensione, quando estiver pronto clique em 'Salvar'
              </Text>
              <Column width='100%' height='100%' justifyContent='space-between' alignItems='center'>
                <Column
                  width='100%'
                  height='100%'
                  justifyContent='center'
                  alignItems='center'
                  gridColumnGap='16px'
                  mb='16px'
                >
                  <AvatarEditor
                    image={image === null ? '/static/images/psicologo-sem-foto.png' : image}
                    width={200}
                    height={200}
                    border={50}
                    borderRadius={100}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={zoom}
                    rotate={rotation}
                    backgroundColor='transparent'
                    ref={editor}
                    onImageChange={onImageChange}
                  />
                  <Text
                    fontSize='14px'
                    fontWeight='600'
                    lineHeight='24px'
                    textAlign='left'
                    color='gray.n750'
                  >
                    Arraste para posicionar
                  </Text>
                </Column>
                <Column width='100%' height='100%' justifyContent='center' alignItems='center'>
                  <Column
                    width='75%'
                    height='auto'
                    justifyContent='center'
                    alignItems='center'
                    gridColumnGap='16px'
                  >
                    <Column width='100%' height='auto' justifyContent='center' alignItems='center'>
                      <Text fontSize='14px' fontWeight='600' lineHeight='24px' textAlign='left'>
                        Zoom
                      </Text>
                      <RangeSlider
                        min={1}
                        max={2}
                        value={zoom}
                        step={0.01}
                        onChange={value => {
                          setZoom(value)
                        }}
                      />
                    </Column>
                  </Column>
                  <Column
                    width='75%'
                    height='auto'
                    justifyContent='center'
                    alignItems='center'
                    gridColumnGap='16px'
                  >
                    <Column width='100%' height='auto' justifyContent='center' alignItems='center'>
                      <Text fontSize='14px' fontWeight='600' lineHeight='24px' textAlign='left'>
                        Rotação
                      </Text>
                      <RangeSlider
                        min={0}
                        max={180}
                        value={rotation}
                        step={1}
                        onChange={value => setRotation(value)}
                      />
                    </Column>
                  </Column>
                </Column>
              </Column>
              <Row width='100%' justifyContent='space-around' mt='24px'>
                <Row>
                  <ButtonLabel
                    flex={1}
                    width='auto'
                    height='58px'
                    fontSize='14px'
                    fontWeight='600'
                    lineHeight='24px'
                    textAlign='left'
                    as='label'
                    htmlFor='change-img'
                  >
                    Alterar foto
                    <FileInput
                      type='file'
                      id='change-img'
                      onChange={onSelectFile}
                      accept='image/*'
                    />
                  </ButtonLabel>
                </Row>
                <Button
                  flex={1}
                  variant='primary'
                  width='auto'
                  height='48px'
                  borderRadius='4px'
                  ml='8px'
                  onClick={mutation.mutateAsync}
                  disabled={image === null}
                >
                  Salvar
                </Button>
              </Row>
            </Column>
          </Column>
        </Column>
      )}
    </Modal>
  )
}

const ButtonLabel = styled.label`
  ${({ theme: { colors } }) => `
  font-family: Fira Sans;
  color: ${colors.primary};
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  border: 1px solid ${colors.primary};
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: 0.2s color ease-in-out;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.primary};
  }

  }`}
`

const FileInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
`

export default ChangeAvatarModal
