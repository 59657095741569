import styled, { keyframes } from 'styled-components'
import { useState, useEffect } from 'react'
import Link from 'next/link'
import { toast } from 'react-toastify'
import Router from 'next/router'
import { useQuery as useApolloQuery } from '@apollo/client'

import { Row, Column } from '../Grid'
import { Text } from '../Text'
import { psychologistContent } from '../../../utils/navbarContents'
import {
  Profile,
  Patients,
  List,
  Calendar,
  Financing,
  Handshake,
  Help,
  News,
  Logout,
  User,
  Thunder
} from '../../../components/v2/Icons'

import { downloadInformePdf } from '../../../services/financial'
import { deleteDataContractId, getDownloadFile } from '../../../utils/helpers'
import { useFeatureFlags } from 'hooks'
import { useQuery } from '@tanstack/react-query'
import { getPendingPatientsCount } from 'services/vittude-care-api'
import { GET_QUESTIONNAIRE_BY_ID } from 'graphql/getQuestionnaire'

const NavbarMobile = ({ user, closeToggle, logoutUser, clearSignUp }) => {
  const [selectedOption, setSelectedOption] = useState(false)
  const [subMenuOptions, setSubMenuOptions] = useState(false)

  const { frontend_vittude_care_menu_enabled } = useFeatureFlags(
    'frontend_vittude_care_menu_enabled'
  )

  const { data: questionnaireResponse } = useApolloQuery(GET_QUESTIONNAIRE_BY_ID, {
    variables: { id: 'clgxpglpz1wun0bm5w79yp1gn' }
  })

  const questions = questionnaireResponse?.questionnaire?.questions
  const questionsIds = questions?.map(question => question?.id)

  const { data: pendingPatientsCount } = useQuery({
    queryKey: ['getPendingPatientsCount', user.id],
    queryFn: () => getPendingPatientsCount(user.id, questionsIds),
    enabled: !!questionsIds?.length && user?.role === 'professional',
    select: ({ data }) => data?.count
  })

  const handleState = path => {
    if (path === selectedOption) {
      setSelectedOption(false)
      return setSubMenuOptions(false)
    }
    if (selectedOption) {
      setSelectedOption(false)
      return setTimeout(() => setSelectedOption(path), 250)
    }
    setSelectedOption(path)
  }

  useEffect(() => {
    const currentOption = psychologistContent.find(option => option.path === selectedOption)
      ?.subnavbar
    setSubMenuOptions(currentOption)
  }, [selectedOption])

  const handleChangeRoute = option => {
    switch (option) {
      default:
        return `/painel?menu=${option}`
    }
  }

  const logout = async () => {
    closeToggle()
    toast.success('Saindo...')
    await logoutUser()
    await clearSignUp()
    deleteDataContractId()
    Router.push({
      pathname: '/'
    })
  }

  const handleDownloadInforme = async () => {
    try {
      const response = await downloadInformePdf()
      getDownloadFile(response, 'informe-rendimentos.pdf')
    } catch (error) {
      toast.error('Houve um problema ao baixar o PDF', { autoClose: 8000 })
      console.log(error)
    }
  }

  return (
    <Row
      width='100%'
      height='90%'
      maxWidth='100%'
      bg='#F8F8F8'
      alignContent='flex-start'
      justifyContent='space-between'
      flexWrap='wrap'
      mb='50px'
      padding={['20px', '30px']}
    >
      <Column width='100%'>
        <Row width='100%' justifyContent='space-evenly' mb='26px'>
          <MenuItem id='meus-dados' href='#!' passHref={true}>
            <MenuLabel
              isActive={selectedOption === 'meus-dados'}
              onClick={() => handleState('meus-dados')}
            >
              <MenuButton isActive={selectedOption === 'meus-dados'}>
                <Profile />
              </MenuButton>
              <MenuText variant='small'>Meu Perfil</MenuText>
            </MenuLabel>
          </MenuItem>
          <Link id='meus-pacientes' href='/painel?menu=meus-pacientes' passHref={true}>
            <MenuLabel
              isActive={selectedOption === 'meus-pacientes'}
              onClick={() => {
                handleState('meus-pacientes')
                closeToggle()
              }}
            >
              <MenuButton isActive={selectedOption === 'meus-pacientes'}>
                <Patients />
              </MenuButton>
              <MenuText variant='small'>Pacientes</MenuText>
            </MenuLabel>
          </Link>
          <MenuItem id='consultas' href='#!' passHref={true}>
            <MenuLabel
              isActive={selectedOption === 'consultas'}
              onClick={() => handleState('consultas')}
            >
              <MenuButton isActive={selectedOption === 'consultas'}>
                <List />
              </MenuButton>
              <MenuText variant='small'>Consultas</MenuText>
            </MenuLabel>
          </MenuItem>
        </Row>
        <Row justifyContent='center'>
          {subMenuOptions && (selectedOption === 'meus-dados' || selectedOption === 'consultas') && (
            <Submenu>
              {subMenuOptions.map((option, index) => (
                <>
                  {option.path === 'rendimentos' && user.download_informe ? (
                    <Row
                      key={index}
                      id={option.path}
                      onClick={() => handleDownloadInforme()}
                      marginBottom='15px'
                      alignItems='center'
                      width='48%'
                    >
                      <SubmenuButton width='100% !important' onClick={() => closeToggle()}>
                        <Text variant='small' fontWeight='bold' color='white'>
                          {option.label}
                        </Text>
                      </SubmenuButton>
                    </Row>
                  ) : (
                    option.path !== 'rendimentos' && (
                      <Link
                        key={index}
                        id={option.path}
                        href={handleChangeRoute(option.path)}
                        passHref={true}
                        marginBottom='15px'
                        alignItems='center'
                      >
                        <SubmenuButton onClick={() => closeToggle()}>
                          <Text variant='small' fontWeight='bold' color='white'>
                            {option.label}
                          </Text>
                        </SubmenuButton>
                      </Link>
                    )
                  )}
                </>
              ))}
            </Submenu>
          )}
        </Row>
      </Column>
      <Column width='100%'>
        <Row width='100%' justifyContent='space-evenly' mb='26px'>
          <MenuItem id='agendas' href='/painel?menu=agendas' passHref={true}>
            <MenuLabel
              isActive={selectedOption === 'agendas'}
              onClick={() => handleState('agendas')}
            >
              <MenuButton isActive={selectedOption === 'agendas'}>
                <Calendar />
              </MenuButton>
              <MenuText variant='small'>Agenda</MenuText>
            </MenuLabel>
          </MenuItem>
          <MenuItem id='painel-financeiro' href='#!' passHref={true}>
            <MenuLabel
              isActive={selectedOption === 'painel-financeiro'}
              onClick={() => handleState('painel-financeiro')}
            >
              <MenuButton isActive={selectedOption === 'painel-financeiro'}>
                <Financing />
              </MenuButton>
              <MenuText variant='small'>Financeiro</MenuText>
            </MenuLabel>
          </MenuItem>
          <Link id='parcerias' href='/painel?menu=parcerias' passHref={true}>
            <MenuLabel
              isActive={selectedOption === 'parcerias'}
              onClick={() => {
                handleState('parcerias')
                closeToggle()
              }}
            >
              <MenuButton isActive={selectedOption === 'parcerias'}>
                <Handshake />
              </MenuButton>
              <MenuText variant='small'>Parcerias</MenuText>
            </MenuLabel>
          </Link>
        </Row>
        <Row>
          {subMenuOptions &&
            (selectedOption === 'agendas' || selectedOption === 'painel-financeiro') && (
              <Submenu>
                {subMenuOptions.map((option, index) => (
                  <Link
                    key={index}
                    id={option.path}
                    href={handleChangeRoute(option.path)}
                    passHref={true}
                    marginBottom='15px'
                    alignItems='center'
                  >
                    <SubmenuButton onClick={() => closeToggle()}>
                      <Text variant='small' fontWeight='bold' color='white'>
                        {option.label}
                      </Text>
                    </SubmenuButton>
                  </Link>
                ))}
              </Submenu>
            )}
        </Row>
      </Column>
      <Column width='100%'>
        <RowGrid>
          {frontend_vittude_care_menu_enabled && (
            <Link id='olhar-do-psicologo' href='/painel?menu=olhar-do-psicologo' passHref={true}>
              <MenuLabel
                isActive={selectedOption === 'olhar-do-psicologo'}
                onClick={() => {
                  handleState('olhar-do-psicologo')
                  closeToggle()
                }}
              >
                <MenuButton isActive={selectedOption === 'olhar-do-psicologo'}>
                  {pendingPatientsCount > 0 && <Notification>{pendingPatientsCount}</Notification>}
                  <Thunder />
                </MenuButton>
                <MenuText variant='small'>Vittude Insights</MenuText>
              </MenuLabel>
            </Link>
          )}
          <Link
            id='ajuda'
            href='https://meajuda.vittude.com/pt-BR/collections/403974-psicologos?_ga=2.1456113.1974295808.1606239719-2102694201.1600709568'
            passHref={true}
            onClick={() => handleState('ajuda')}
          >
            <MenuLabel
              isActive={selectedOption === 'ajuda'}
              onClick={() => setSelectedOption('ajuda')}
            >
              <MenuButton isActive={selectedOption === 'ajuda'}>
                <Help />
              </MenuButton>
              <MenuText variant='small'>Ajuda</MenuText>
            </MenuLabel>
          </Link>
          <MenuLabel isActive={selectedOption === 'logout'} onClick={() => logout()}>
            <MenuButtonLogout isActive={selectedOption === 'logout'}>
              <Logout fill='none' />
            </MenuButtonLogout>
            <MenuText variant='small'>Sair</MenuText>
          </MenuLabel>
        </RowGrid>
      </Column>
    </Row>
  )
}

const MenuItem = styled(Row)``

const initial = () => keyframes`
  from {
    max-height: 0px;
  }
  to {
    max-height: 200px;
  }
`

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const MenuLabel = styled.a`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  text-decoration: none;

  & > p {
    color: '#BDC3C7';
  }
`

const MenuText = styled(Text)`
  text-align: center;
  color: #313a4d;
`

const MenuButton = styled(Column)(
  ({ isActive }) => `
  position: relative;
  background-color: ${isActive ? '#313A4D' : '#E7EAED'};
  width: 68px;
  height: 68px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 11px;

  svg {
    path {
      fill: ${isActive ? 'white' : '#313A4D'};
    }
    rect{
      fill: ${isActive ? 'white' : '#313A4D'};
    }
  }
`
)

const MenuButtonLogout = styled(Column)(
  ({
    isActive,
    theme: {
      colors: { darkPrimary, gray }
    }
  }) => `
  background-color: ${isActive ? darkPrimary : gray.n250};
  width: 68px;
  height: 68px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 11px;

  svg {
    path {
      stroke: ${isActive ? 'white' : '#313A4D'};
    }
  }
`
)

const Submenu = styled.a`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  animation: ${initial} 0.5s ease-out;
  justify-content: space-evenly;
  margin-bottom: 26px;

  div:nth-child(2n) {
    margin-left: 10px;
    margin-bottom: 10px;
  }
`

const SubmenuButton = styled(Column)`
  display: flex;
  width: 48%;
  max-width: 195px;
  height: 68px;
  background: #ff7708;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: ${opacity} 0.4s ease 0.4s forwards;
  margin-bottom: 10px;
`

const RowGrid = styled(Row)`
  display: grid;
  grid-template-columns: repeat(3, 68px);
  justify-content: space-evenly;
  width: 100%;
  grid-row-gap: 26px;
`

const Notification = styled(Column)`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  color: white;
`

export { NavbarMobile }
