import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Chevron } from '../Icons'
import { Row } from '../Grid'
import { Title } from '../Title'
import { Text } from '../Text'
import { NextImage } from '../NextImage'

const Accordion = ({
  label,
  children,
  disabled,
  opened,
  containerStyle,
  dataStyle,
  isCustomizable,
  handlerAccordion,
  paddingX,
  overflowX,
  error,
  seeMore,
  chevronColor = '#FF7708',
  titleStyle,
  ...props
}) => {
  const [isOpened, setIsOpened] = useState(opened)
  const innerAccordionRef = useRef(null)
  const heightTitleAccordionRef = useRef(null)

  useEffect(() => {
    setTimeout(() => setIsOpened(opened))
  }, [opened])

  const handlerAccordionOpen = () => {
    if (handlerAccordion) {
      return handlerAccordion(!isOpened)
    }
    return setIsOpened(!isOpened)
  }

  return (
    <Row flexWrap='wrap' minWidth='100%' {...props}>
      <AccordionContent
        ref={innerAccordionRef}
        height={isOpened ? 'auto' : '64px'}
        overflow={isOpened ? 'inherit' : 'hidden'}
        px={paddingX}
        style={{ transition: '0.5s all', cursor: 'pointer' }}
        {...containerStyle}
      >
        <AccordionHeader
          justifyContent={'space-between'}
          alignItems={'center'}
          ref={heightTitleAccordionRef}
          onClick={handlerAccordionOpen}
        >
          {props.aceptsIcon ? (
            <>
              <AccordionIconAndTitleContainer>
                <AccordionIcon>
                  <NextImage src={props.icon} width={20} height={20} />
                </AccordionIcon>
                <Title
                  type='h3'
                  variant='dark'
                  fontSize={['14px', '14px', '14px', '16px']}
                  lineHeight='24px'
                  fontWeight='normal'
                  py='21px'
                  maxWidth='80%'
                  {...titleStyle}
                >
                  {label}
                </Title>
              </AccordionIconAndTitleContainer>
              <Row alignItems='center'>
                <Row gridColumnGap='10px' alignItems='center' p='20px'>
                  {props.helperText ? (
                    <NextImage
                      src={
                        props.secondIcon
                          ? props.secondIcon
                          : '/static/images/psicologo-sem-foto.png'
                      }
                      width={17}
                      height={17}
                    />
                  ) : (
                    ''
                  )}

                  <Text variant='small' fontWeight='600' color='#808C8D' textAlign='left'>
                    {props.helperText}
                  </Text>
                </Row>
                <Chevron size={20} color={chevronColor} variant={!isOpened ? 'down' : 'up'} />
              </Row>
            </>
          ) : (
            <>
              <Title
                type='h3'
                variant='dark'
                fontSize={['14px', '14px', '14px', '16px']}
                lineHeight='24px'
                fontWeight='normal'
                py='21px'
                maxWidth='80%'
                {...titleStyle}
              >
                {label}
              </Title>
              <Row alignItems='center'>
                {seeMore}
                <Chevron size={20} color={chevronColor} variant={!isOpened ? 'down' : 'up'} />
              </Row>
            </>
          )}
        </AccordionHeader>
        <Row flexWrap='wrap' minWidth='100%' pb='21px' overflowX={isOpened ? overflowX : 'hidden'}>
          {isCustomizable ? (
            children
          ) : (
            <Text width='100%' color='#7f8c8d' variant='small' lineHeight='21px'>
              {children}
            </Text>
          )}
        </Row>
      </AccordionContent>
    </Row>
  )
}

const AccordionContent = styled(Row)`
  background: #fff;
  border: 1px solid #ebebed;
  border-radius: 4px;
  overflow: hidden;
  align-items: flex-start;
  transition: 0.5s all;
  flex-wrap: wrap;
  min-width: 100%;
`
const AccordionHeader = styled(Row)`
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
  min-width: 100%;
`
const AccordionIconAndTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 40%;
`

const AccordionIcon = styled.div`
  width: 20px;
  margin-right: 0.5rem;
`

Accordion.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
  disabled: PropTypes.bool,
  seeMore: PropTypes.element,
  isCustomizable: PropTypes.bool
}

Accordion.defaultProps = {
  isOpened: false,
  isCustomizable: false,
  paddingX: '20px',
  overflowX: 'hidden'
}

export { Accordion }
