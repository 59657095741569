import React from 'react'
import { useRouter } from 'next/router'

import { Column } from 'components/v2/Grid'
import HeaderBanner from './HeaderBanner'
import { HomeHeader, HomeHeaderMobile } from '.'

import { useCoupon } from 'providers/CouponProvider'
import { useDevice, useFeatureFlags } from 'hooks'

const ROUTES = [
  '/',
  '/busca',
  '/agendar-consultas/finalizar-pagamento',
  '/encontre-seu-psicologo',
  '/encontre-seu-psicologo/resultados'
]

const HeaderRendering = ({ openModal }) => {
  const { pathname } = useRouter()
  const { isMobile, isTablet } = useDevice()
  const { isUserB2B, isFirstConsultation } = useCoupon()
  const { frontend_is_accessible_consultation_banner_visible } = useFeatureFlags(
    'frontend_is_accessible_consultation_banner_visible'
  )

  const isAccessibleConsultation =
    isFirstConsultation &&
    !isUserB2B &&
    frontend_is_accessible_consultation_banner_visible &&
    ROUTES.includes(pathname)

  if (isAccessibleConsultation) {
    return (
      <Column gridColumnGap='16px'>
        <HeaderBanner />
        {isMobile || isTablet ? (
          <HomeHeaderMobile openModal={openModal} />
        ) : (
          <HomeHeader openModal={openModal} />
        )}
      </Column>
    )
  }

  return isMobile || isTablet ? (
    <HomeHeaderMobile openModal={openModal} />
  ) : (
    <HomeHeader openModal={openModal} />
  )
}

export { HeaderRendering }
