import Slider from 'rc-slider'
import styled from 'styled-components'

const RangeSlider = props => <StyledSlider {...props} />

const StyledSlider = styled(Slider)`
  position: relative;
  width: 100%;
  height: 14px;
  padding: 5px 0;
  margin-right: 12px;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  & * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .rc-slider-rail {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.gray.n300};
    border-radius: 6px;
  }

  .rc-slider-track {
    position: absolute;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 6px;
  }

  .rc-slider-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: -9px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    touch-action: pan-x;
    border: 1px solid #d5d5d5;
  }

  &.rc-slider-disabled {
    background-color: ${({ theme }) => theme.colors.gray.n100};
  }

  &.rc-slider-disabled .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.gray.n300};
  }

  &.rc-slider-disabled .rc-slider-handle,
  &.rc-slider-disabled .rc-slider-dot {
    background-color: ${({ theme }) => theme.colors.gray.n300};
    box-shadow: none;
    cursor: not-allowed;
  }

  &.rc-slider-disabled .rc-slider-mark-text,
  &.rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
  }
`

export { RangeSlider }
