import React, { useState } from 'react'
import styled from 'styled-components'

import { useCoupon } from 'providers/CouponProvider'

const HeaderBanner = () => {
  const [showBannerInCheckout, setShowBannerInCheckout] = useState(false)
  const { isUserB2B, isFirstConsultation, coupon, isCheckoutValidToApplyCoupon } = useCoupon()

  setTimeout(() => {
    setShowBannerInCheckout(true)
  }, 10000)

  const couponValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(coupon?.coupon_value)

  if (isUserB2B || !isFirstConsultation) {
    return null
  }

  if (isCheckoutValidToApplyCoupon) {
    return (
      <BannerContainer
        id='header-banner'
        style={showBannerInCheckout ? { display: 'none' } : { display: 'flex' }}
      >
        <BannerText>
          <h1 style={{ padding: '16px' }}>Cupom de primeira consulta aplicado com sucesso!</h1>
        </BannerText>
      </BannerContainer>
    )
  }

  return (
    <BannerContainer id='header-banner'>
      <BannerText>
        <h1>Use o cupom PRIMEIRACONSULTA de {couponValue} reais na sua primeira sessão</h1>
        <p>*cupom não acumulativo com outros descontos, promoções ou benefícios corporativos.</p>
      </BannerText>
    </BannerContainer>
  )
}

export default HeaderBanner

const BannerContainer = styled.div`
  background-color: #ff7708;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 75px;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`

const BannerText = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    color: #fff;

    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: #fff;

    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 0.625rem;
      text-align: center;
    }
  }
`
