import React, { useContext } from 'react'

import ActiveLink from '../../shared/ActiveLink'
import { HeaderNavItem } from './Header'
import FavoriteContext from '../../../context/favoriteContext'

const FavoriteLink = () => {
  const { likeProfessionals } = useContext(FavoriteContext)

  return likeProfessionals.length === 0 ? (
    <HeaderNavItem>
      <ActiveLink href={'/blog/'}>Blog</ActiveLink>
    </HeaderNavItem>
  ) : (
    <HeaderNavItem>
      <ActiveLink href={'/favoritos/'}>Favoritos</ActiveLink>
    </HeaderNavItem>
  )
}

export { FavoriteLink }
