import api from './api'

import { getTimezone } from '../utils/helpers'

export const getCurrentClinics = (professionalId, isVirtual) =>
  api.get(`/month-plan/professional/${professionalId}/schedules/?is_virtual=${isVirtual}`)

export const getWeekDays = (scheduleId, timezone, isEveryOtherWeek = false) =>
  api.get(
    `/month-plan/weekly-availability/schedule/${scheduleId}/week-days/?timezone=${timezone}&is_every_other_week=${isEveryOtherWeek}`
  )

export const getHoursAndDatesPatient = async (params, scheduleId) =>
  api.get(`/month-plan/weekly-availability/schedule/${scheduleId}/hours/`, { params })

export const getHoursAndDatesPsico = async (params, scheduleId) =>
  api.get(`/month-plan/professional/weekly-availability/schedule/${scheduleId}/hours/`, { params })

export const getMonthplan = timezone =>
  api.get(`/month-plan/patient/?timezone=${timezone ?? getTimezone()}`).then(res => {
    if (res.status == 204) {
      throw Error('Paciente sem plano de consultas')
    }

    return res
  })

export const createMonthplan = (timezone, data, isEveryOtherWeek = false) =>
  api.post(
    `/month-plan/?timezone=${timezone}&create_default_invoice_payment=false&is_every_other_week=${isEveryOtherWeek}`,
    data
  )

export const getMonthplanTotalPrice = (scheduleId, patientId, consultationDates, value) =>
  api.get(
    `/month-plan/schedule/${scheduleId}/price-preview/${patientId}/?consultation_dates=${consultationDates}&value=${value}`
  )

export const getProfessionalMonthplan = (timezone, patientId) =>
  api.get(`/month-plan/professional/?timezone=${timezone}&patient_id=${patientId}`).then(res => {
    if (res.status == 204) {
      throw Error('Paciente sem plano de consultas')
    }

    return res
  })

export const createProfessionalMonthplan = (timezone, payload) =>
  api.post(
    `/month-plan/professional/?timezone=${timezone}&create_default_invoice_payment=false`,
    payload
  )

export const updateProfessionalMonthplan = (monthplanId, timezone, payload) =>
  api.put(
    `/month-plan/professional/${monthplanId}/?timezone=${timezone}&create_default_invoice_payment=false`,
    payload
  )

export const deleteProfessionalMonthplan = (monthplanId, timezone) =>
  api.delete(`/month-plan/professional/${monthplanId}/?timezone=${timezone}`)

export const deletePatientMonthPlan = (monthplanId, timezone) =>
  api.delete(`/month-plan/patient/${monthplanId}/?timezone=${timezone}`)
