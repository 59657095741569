import styled from 'styled-components'
import { space, layout, position, color, flexbox, typography, background } from 'styled-system'

const HeaderNavbar = styled.div`
  visibility: visible;
  z-index: 9999999;
  background: #ffffff;
  position: relative;
  top: 0;
  left: 0;
  color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  ${space}
  ${layout}
  ${position}
  ${color}
`

const HeaderNavItem = styled.li`
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin: 0 15px !important;
  align-items: center;
  text-align: center;
  display: flex;
  @media (max-width: 1024px) {
    margin: 0 5px !important;
  }
  @media (max-width: 600px) {
    margin: 0 15px !important;
    align-items: flex-start;
    text-align: left;
  }
  ${layout}
`
const HeaderNavLink = styled.a`
  align-items: center;
  display: flex;
  color: #29374b;
  line-height: 38px;
  text-decoration: none;
  font-family: 'Fira Sans';
  padding: 0 !important;
  &.active {
    color: #FF7708;
  }
  &:hover {
    color: #FF7708;
  }
  @media (max-width: 600px) {
    margin-bottom: 5px;
    text-align: left;
  }
  ${color}
  ${space}
  ${layout}
  ${typography}
  ${background}
`
const HeaderLogo = styled.div`
  display: flex;
  flex: 1;
`

const HeaderNav = styled.ul`
  display: flex;
  flex: 1;
  margin: 0;
  padding:0;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  ${layout}
  ${space}
  ${flexbox}
`
const HeaderNavLogo = styled.div`
  display: flex;
  cursor: pointer;
  ${layout}
  ${flexbox}
`
const HeaderCollapse = styled.div`
  display: flex;
  @media(max-width: 600px) {
    display:none;
    position: fixed;
    z-index: 2;
    width: 90%;
    height: 100%;
    background: #fff;
    top: 0;
    left:-9999px;
    transition: .5s;
    padding-top: 60px;
    &.active{
      display:flex;
      left: 0;
    }
  }
  ${space}
  ${layout}
  ${position}
`
const HeaderToggler = styled.button`
  display: flex;
  background: none;
  box-shadow: none;
  border: none;
  position: relative;
  z-index: 3;
  top: 2px;
  padding: 0;
  &:focus {
    outline: none;
  }
  span {
    display: block;
    width: 27px;
    height: 2px;
    background: #fff;
    margin-bottom: 4px;
    transition: 0.4s;
  }
  &.active-toggler {
    span:nth-child(2) {
      transform: rotate(-45deg);
      top: 0;
    }
    span:nth-child(1) {
      display: none;
    }
    span:nth-child(3) {
      transform: rotate(45deg);
      bottom: 7px;
      position: relative;
    }
  }
  ${space}
  ${layout}
`
const HeaderNavThumb = styled.div`
  display: flex;
  ${layout}
`
const HeaderNavThumbImg = styled.div`
  display: flex;
  position: relative;
  img {
    border-radius: 100%;
  }
  .change-thumb {
    position: absolute;
    text-align: center;
    top: 0px;
    left: 0px;
    background: #ff7708;
    color: #fff;
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-weight: 600;
    padding-top: 24px;
    opacity: 0;
    cursor: pointer;
    transition: all 0.3s ease;
    input {
      display: none;
    }
  }
  &:hover {
    .change-thumb {
      opacity: 0.8;
    }
  }
  ${layout}
`
const HeaderNavBackdrop = styled.div`
  position: relative;
  .profile-thumb {
    border-radius: 6px;
    background-color: #f5f5f5;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    position: absolute;
    top: 52px;
    right: 14px;
    width: 300px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.67;
    letter-spacing: -0.1px;
    color: #000;
    z-index: 9999999;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  ${layout}
`

export {
  HeaderLogo,
  HeaderNavbar,
  HeaderNavLink,
  HeaderNavItem,
  HeaderNav,
  HeaderNavLogo,
  HeaderCollapse,
  HeaderToggler,
  HeaderNavThumb,
  HeaderNavThumbImg,
  HeaderNavBackdrop
}
