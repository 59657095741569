import api from './api'
import moment from 'moment'

export const financialDetail = async (user_id, options, page /*eslint-disable-line */) =>
  new Promise((resolve, reject) => {
    api
      .post(
        `/professional/${user_id}/financial_detail/?page=${page}`,
        options
      ) /*eslint-disable-line */
      .then(response => {
        const json = {}
        json.page = response.data.page
        json.count = response.data.count
        json.next = response.data.next
        json.previous = response.data.previous
        json.receivings = response.data.content
        json.receivings.forEach(function(e, i) {
          let cpDate = moment.utc(e.date, 'X')
          cpDate.add(moment().utcOffset(), 'minutes')
          json.receivings[i].date = cpDate.format('DD/MM/YYYY')
          json.receivings[i].time = cpDate.format('HH:mm')
          cpDate = moment.utc(e.status.date, 'X')
          cpDate.add(moment().utcOffset(), 'minutes')
          json.receivings[i].status_date = cpDate.format('DD/MM/YYYY')
          json.receivings[i].status_time = cpDate.format('HH:mm')
        })
        resolve(json)
      })
      .catch(error => reject(error))
  })

export const financialSummary = async (user_id /*eslint-disable-line */) =>
  await api.get(`/professional/${user_id}/financial_summary/`) /*eslint-disable-line */

export const requestWithdraw = async (user_id /*eslint-disable-line */) =>
  await api.get(`/professional/${user_id}/request_withdraw/`) /*eslint-disable-line */

export const getUpcomingEarnings = async () =>
  await api.get('/financial/professional/upcoming-earnings/')

export const getWithdraw = async () => await api.get('/financial/professional/withdraw/')

export const getSummary = async ({ startDate, endDate, ...rest }) =>
  await api.get(`/financial/professional/summary/${startDate}/${endDate}`, {
    params: { ...rest }
  })

export const downloadInformePdf = async () => await api.get('/reveneu_pdf/')

export const getProfessionalReleases = async ({
  startDate,
  endDate,
  page,
  order_by,
  order,
  per_page,
  ...rest
}) =>
  await api.get(`/financial/professional/statement/${startDate}/${endDate}`, {
    params: { page, order_by, order, per_page, ...rest }
  })

export const downloadReleasesReport = async ({ startDate, endDate, ...rest }) =>
  api.get(`/financial/professional/statement-spreadsheet/${startDate}/${endDate}`, {
    params: { ...rest },
    responseType: 'blob'
  })

export const getFilters = async () => await api.get('/financial/professional/filters/')

export const getWithdrawList = async (startDate, endDate, page) =>
  await api.get(`/financial/professional/withdraw-list/${startDate}/${endDate}/?page=${page}`)

export const resendWithdrawEmail = async withdrawId =>
  await api.get(`/financial/professional/withdraw-email/${withdrawId}/`)

export const requestDemonstrativeByEmail = async () => {
  return api.get('/financial/professional/statement-pdf/')
}

export const requestPatientStatementByEmail = async () => {
  return api.get('/financial/professional/statement-patients-pdf')
}

export const requestAnnualPaymentStatementTabByEmail = async () =>
  api.get('/financial/professional/annual_payment_statement/')
