import React from 'react'
import styled from 'styled-components'
import Router from 'next/router'
import { Row } from '../Grid'

const SectionsItemMobile = ({ label, route }) => (
  <SectionsRow onClick={() => Router.push(route)}>{label}</SectionsRow>
)

SectionsItemMobile.defaultProps = {
  route: '',
  label: ''
}

const SectionsRow = styled(Row)`
  height: 56px;
  border-top: 1px solid #e7eaf4;
  align-items: center;
  padding: 0 20px;
  font-weight: 400;
  font-family: Sora;
`

export { SectionsItemMobile }
