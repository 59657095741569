import { gql } from '@apollo/client'
export const GET_QUESTIONNAIRE_BY_ID = gql`
  query GetQuestionnaire($id: ID!) {
    questionnaire(where: { id: $id }) {
      id
      title
      questions {
        ...questionsOnQuestionnaires
      }
    }
  }
  fragment questionsOnQuestionnaires on Question {
    id
    text
    answers {
      ...answersOnQuestions
    }
  }
  fragment answersOnQuestions on Answer {
    id
    text
    asset {
      id
      width
      height
      fileName
      url(transformation: { document: { output: { format: webp } } })
    }
  }
`
