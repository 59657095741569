import styled from 'styled-components'
import { Column, Row } from 'components/v2/Grid'
import { NextImage } from 'components/v2/NextImage'
import { Tooltip } from '../Tooltip'
import { Text } from 'components/v2/Text'
import { Accordion } from '../Accordion'
import { theme } from 'utils/theme'
import { getIconForIncident, formatForecast, formatDescription } from './utils/beacon-utils'
import { useDevice } from 'hooks'
import { sendBeaconIncidentEvent } from 'components/shared/DataLayer'

const Beacon = ({ isActive, icon, havingIncidents, incidents }) => {
  const { isMobile, isTablet } = useDevice()

  const handleClick = incident => {
    const incidentGA = {
      title: incident.title,
      state: incident.type
    }
    sendBeaconIncidentEvent(incidentGA)
  }

  return (
    <BeaconMenu active={isActive}>
      <Row width='100%' justifyContent='space-between' alignItems='center'>
        <Row gridColumnGap='10px' alignItems='center' p='20px'>
          <NextImage src={icon} width={28} height={28} />

          <Text
            variant={isMobile || isTablet ? 'regular' : 'big'}
            fontWeight='600'
            color='#323B4D'
            textAlign='left'
          >
            {havingIncidents ? 'Status da plataforma' : 'Todos os sistemas normais'}
          </Text>
        </Row>

        <Row mr='15px'>
          <Tooltip
            variant={isMobile || isTablet ? 'left' : 'right'}
            backgroundColor='snow'
            boxShadow={`${theme.shadows.boxShadow}`}
            borderRadius='4px'
            width={['92px', '148px']}
            py={['8px', '12px']}
            px={['4px', '8px']}
          >
            <Text
              variant={['teeny', 'tiny']}
              color='gray.n500'
              fontWeight='bold'
              mb={['4px', '8px']}
            >
              Menteremos você informado dos incidentes da plataforma aqui
            </Text>
          </Tooltip>
        </Row>
      </Row>
      {incidents?.map((incident, index) => (
        <Accordion
          onClick={() => handleClick(incident)}
          key={index}
          label={incident?.title}
          opened={false}
          aceptsIcon={true}
          icon={getIconForIncident(incident)}
          secondIcon={'/static/images/clock-icon.svg'}
          helperText={formatForecast(incident)}
          titleStyle={{
            fontSize: isMobile || isTablet ? '12px' : '16px',
            lineHeight: isMobile || isTablet ? '22px' : '24px',
            fontWeight: '600',
            color: 'black'
          }}
        >
          {formatDescription(incident?.description).map(paragraph => (
            <Text variant={isMobile || isTablet ? 'small' : 'regular'} textAlign={'left'}>
              {paragraph}
            </Text>
          ))}
        </Accordion>
      ))}
    </BeaconMenu>
  )
}

const BeaconMenu = styled(Column)`
  ${({ theme: { colors }, active }) => `
  position: absolute;
  top: 60px;
  right: 0;
  width: 32.5rem;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 24%);

  opacity: ${active === true ? '1' : '0'};
  visibility: ${active === true ? 'visible' : 'hidden'};
  transition: 0.3s all;

  @media (max-width: 1024px) {
    right: 0px;
  }

  @media (max-width: 640px) {
    min-height: 48px;
    width 100%
  }
`}
`

export { Beacon }
