import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Column, Row } from '../Grid'
import { Text } from '../Text'
import { Loader } from '../Loader'
import { VerifiedIcon } from '../Icons'

import { getTimezones } from 'services/timezone'
import TimezoneContext from 'context/timezoneContext'

const TimezoneSelect = () => {
  const [timeZoneList, setTimeZoneList] = useState([])
  const [listOfRegion, setListOfRegion] = useState([])
  const [timeZoneArray, setTimeZoneArray] = useState([])
  const [searchTimezone, setSearchTimezone] = useState('')
  const [selectedTimeZone, setSelectedTimeZone] = useState([])
  const [formattedTimeZoneList, setFormattedTimeZoneList] = useState([])

  const { timezone, setTimezone, setTimezoneGTM } = useContext(TimezoneContext)

  useEffect(() => {
    findTimeZones()
  }, [])

  useEffect(() => {
    handlePlaceSeparation()
  }, [timeZoneList])

  useEffect(() => {
    handleSectionRegion()
  }, [formattedTimeZoneList])

  useEffect(() => {
    handleRefactList()
  }, [listOfRegion])

  useEffect(() => {
    handleSubmitTimezone()
  }, [timeZoneArray])

  const findTimeZones = async () => {
    try {
      const timeZone = await getTimezones()
      setTimeZoneList(timeZone.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePlaceSeparation = () => {
    const FormatedTimeZone = timeZoneList.map(location =>
      location.local.includes('/')
        ? {
            region: location.local?.split('/')[0],
            local: location.local
              ?.split('/')
              .slice(1)
              .join('/')
              .replaceAll('_', ' ')
              .replace('/', ' - '),
            gmt: location?.GMT,
            value: location.local
          }
        : {
            region: location.local,
            local: location.local,
            gmt: location?.GMT,
            value: location.local
          }
    )

    setFormattedTimeZoneList(FormatedTimeZone)
  }

  const handleSectionRegion = () => {
    const separatedRegions = [...new Set(formattedTimeZoneList?.map(e => e.region))]
    setListOfRegion(separatedRegions)
  }

  const handleRefactList = () => {
    const refactList = listOfRegion?.map(region => ({
      region: region,
      cities: formattedTimeZoneList.filter(list => list.region === region)
    }))

    setTimeZoneArray(refactList)
  }

  const handleSubmitTimezone = () => {
    const finder = timeZoneArray.find(TzArray => TzArray.region.includes(timezone?.split('/')[0]))

    finder && setSelectedTimeZone(finder?.cities?.find(cities => cities.value === timezone))
  }

  const filterTimezones = (searchTimezone, timeZoneArray) => {
    const normalizedSearch = searchTimezone
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^\w\s]/gi, '')

    const regex = new RegExp(normalizedSearch, 'i')

    const filteredItems = timeZoneArray?.reduce((result, location) => {
      const matchingCities = location.cities?.filter(city =>
        regex.test(
          city.local
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )
      )

      return [...result, ...matchingCities]
    }, [])

    let filteredRegions = {}

    filteredItems.forEach(item => {
      if (!filteredRegions[item.region]) {
        filteredRegions[item.region] = []
      }

      filteredRegions[item.region].push(item)
    })

    return filteredRegions
  }

  const filteredTMZ = filterTimezones(searchTimezone, timeZoneArray)
  const regions = Object.keys(filteredTMZ)

  return (
    <DropdownTimeZoneMenuWrapper>
      <Column height='100%' width='100%'>
        {selectedTimeZone.length === 0 ? (
          <Column width='100%' height='100%' alignItems='center' justifyContent='space-around'>
            <Loader />
          </Column>
        ) : (
          <>
            <Text variant='regular'>Configure seu fuso horário</Text>
            <Row>
              {timeZoneArray.length > 0 && (
                <Row width='100%' my='20px' justifyContent='space-between'>
                  <Row alignItems='center'>
                    <Row mr='10px'>
                      <VerifiedIcon />
                    </Row>
                    <Row width='150px'>
                      <Text variant='small' color='#FF7708' fontWeight='bold'>
                        {`${selectedTimeZone?.region} - ${selectedTimeZone?.local}`}
                      </Text>
                    </Row>
                  </Row>
                  <Row width='65px'>
                    <Text variant='small' color='#FF7708' fontWeight='bold'>
                      {selectedTimeZone?.gmt >= 0
                        ? `GMT +${selectedTimeZone?.gmt}`
                        : `GMT ${selectedTimeZone?.gmt}`}
                    </Text>
                  </Row>
                </Row>
              )}
            </Row>
            <Row borderBottom='1px solid #DFDFDF' width='250px' mb='24px' />
            <Text variant='small' mb='12px'>
              Pesquisar por região ou cidade
            </Text>
            <TimezoneInput
              placeholder='Pesquise por região ou cidade ex: São Paulo'
              value={searchTimezone}
              onChange={e => setSearchTimezone(e.target.value)}
            />
            <StyledColumnList width='100%'>
              {regions?.map(region => (
                <Column>
                  <Row mt='10px'>
                    <Text color='#A1A1A1'>{region}</Text>
                  </Row>

                  {filteredTMZ[region].map(location => (
                    <ClickableRow
                      onClick={() => {
                        setSelectedTimeZone(location)
                        setTimezone(location.value)
                      }}
                    >
                      <Text width='150px' variant='small'>
                        {location?.local}
                      </Text>

                      <Text variant='small' width='65px'>
                        {location?.gmt >= 0 ? `GMT +${location?.gmt}` : `GMT ${location?.gmt}`}
                      </Text>
                    </ClickableRow>
                  ))}
                </Column>
              ))}

              {filteredTMZ?.length === 0 && (
                <Text variant='small'>Nenhum fuso horário encontrado</Text>
              )}
            </StyledColumnList>
          </>
        )}
      </Column>
    </DropdownTimeZoneMenuWrapper>
  )
}

const DropdownTimeZoneMenuWrapper = styled(Row)`
  position: absolute;
  top: 30px;
  right: 1px;
  background: #ffffff;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 288px;
  height: 380px;
  padding: 20px;
  text-align: left;
  z-index: 1;
  @media (max-width: 425px) {
    top: 0px;
    height: 832px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }

  @media (max-width: 320px) {
    width: 260px;
  }
`

const StyledColumnList = styled(Column)`
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
`

const ClickableRow = styled(Row)`
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: 6px;
  justify-content: space-between;
  &:hover > p {
    opacity: 0.6;
    transition: 0.4s;
  }
`

const TimezoneInput = styled.input`
  width: 250px;
  height: 32px !important;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 8px !important;
  font-size: 14px;
  line-height: 16px;
  color: #a1a1a1;
  margin-bottom: 16px !important;
  ::placeholder {
    color: #a1a1a1;
    font-size: 11px;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`

export { TimezoneSelect }
